export const SidebarCommonItems = [
    {
        type: "heading", label: "Headline", icon: "add-arrow.svg", svg: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M6.00279 20H2V0H6.00279V8.00558H13.9944V0H17.9972V20H13.9944V12.0084H6.00279V20Z" fill="#0084BE" />
        </svg>
    },
    {
        type: "paragraph", label: "Paragraph", icon: "add-arrow.svg", svg: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M9 1C9 0.447715 9.44772 0 10 0H19C19.5523 0 20 0.447715 20 1C20 1.55228 19.5523 2 19 2H10C9.44771 2 9 1.55228 9 1Z" fill="#00A4BF" />
            <path d="M9 7C9 6.44772 9.44772 6 10 6H19C19.5523 6 20 6.44772 20 7C20 7.55228 19.5523 8 19 8H10C9.44771 8 9 7.55228 9 7Z" fill="#00A4BF" />
            <path d="M1 12C0.447715 12 0 12.4477 0 13C0 13.5523 0.447716 14 1 14H19C19.5523 14 20 13.5523 20 13C20 12.4477 19.5523 12 19 12H1Z" fill="#00A4BF" />
            <path d="M0 19C0 18.4477 0.447715 18 1 18H19C19.5523 18 20 18.4477 20 19C20 19.5523 19.5523 20 19 20H1C0.447716 20 0 19.5523 0 19Z" fill="#00A4BF" />
            <path fillRule="evenodd" clipRule="evenodd" d="M4.15316 0L7 8H5.4803L4.91093 6.21978H2.08113L1.5145 8H0L2.83123 0H4.15316ZM2.46064 5.02747H4.5296L3.49263 1.7852L2.46064 5.02747Z" fill="#00A4BF" />
        </svg>
    },
    {
        type: "divideLine", label: "Divider line", icon: "add-arrow.svg", svg: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M3.5 2C3.22386 2 3 2.22386 3 2.5C3 2.77614 3.22386 3 3.5 3H16.5C16.7761 3 17 2.77614 17 2.5C17 2.22386 16.7761 2 16.5 2H3.5Z" fill="#00C1BF" />
            <path d="M3 5.5C3 5.22386 3.22386 5 3.5 5H16.5C16.7761 5 17 5.22386 17 5.5C17 5.77614 16.7761 6 16.5 6H3.5C3.22386 6 3 5.77614 3 5.5Z" fill="#00C1BF" />
            <path d="M0 10C0 9.17157 0.671573 8.5 1.5 8.5H18.5C19.3284 8.5 20 9.17157 20 10C20 10.8284 19.3284 11.5 18.5 11.5H1.5C0.671573 11.5 0 10.8284 0 10Z" fill="#00C1BF" />
            <path d="M3.5 14C3.22386 14 3 14.2239 3 14.5C3 14.7761 3.22386 15 3.5 15H16.5C16.7761 15 17 14.7761 17 14.5C17 14.2239 16.7761 14 16.5 14H3.5Z" fill="#00C1BF" />
            <path d="M3 17.5C3 17.2239 3.22386 17 3.5 17H16.5C16.7761 17 17 17.2239 17 17.5C17 17.7761 16.7761 18 16.5 18H3.5C3.22386 18 3 17.7761 3 17.5Z" fill="#00C1BF" />
        </svg>
    },
    {
        type: "image", label: "Image", icon: "add-arrow.svg", svg: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clipPath="url(#clip0_1228_27713)">
                <path d="M7.50076 7.50022C8.65135 7.50022 9.58408 6.56749 9.58408 5.4169C9.58408 4.26632 8.65135 3.33358 7.50076 3.33358C6.35018 3.33358 5.41744 4.26632 5.41744 5.4169C5.41744 6.56749 6.35018 7.50022 7.50076 7.50022Z" fill="#19BBB9" />
                <path d="M3.3325 11.3252L4.40749 10.2419C4.48496 10.1638 4.57713 10.1018 4.67867 10.0595C4.78022 10.0172 4.88914 9.99539 4.99915 9.99539C5.10916 9.99539 5.21808 10.0172 5.31963 10.0595C5.42118 10.1018 5.51335 10.1638 5.59082 10.2419L7.49914 12.1585L11.9074 7.7419C11.9849 7.6638 12.0771 7.6018 12.1786 7.55949C12.2802 7.51719 12.3891 7.49541 12.4991 7.49541C12.6091 7.49541 12.718 7.51719 12.8196 7.55949C12.9211 7.6018 13.0133 7.6638 13.0908 7.7419L16.6657 11.3252V16.6668H3.3325V11.3252Z" fill="#19BBB9" />
                <path fillRule="evenodd" clipRule="evenodd" d="M0 2.49998C0 1.83695 0.26339 1.20107 0.732228 0.732228C1.20107 0.26339 1.83695 0 2.49998 0H12.4999L12.515 0.000135831H17.4999C18.1629 0.000135831 18.7988 0.263526 19.2676 0.732364C19.7365 1.2012 19.9999 1.83708 19.9999 2.50012V17.4999C19.9999 18.1629 19.7365 18.7988 19.2676 19.2676C18.7988 19.7365 18.1629 19.9999 17.4999 19.9999H12.5149L12.4999 20H2.49998C1.83695 20 1.20107 19.7366 0.732228 19.2678C0.26339 18.7989 0 18.1631 0 17.5V2.49998ZM1.67073 17.5822C1.68967 17.773 1.77399 17.9524 1.91073 18.0891C2.06701 18.2454 2.27897 18.3332 2.49998 18.3332H17.4999C17.7209 18.3332 17.9329 18.2454 18.0891 18.0891C18.2454 17.9329 18.3332 17.7209 18.3332 17.4999V2.50012C18.3332 2.27911 18.2454 2.06715 18.0891 1.91087C17.9329 1.75459 17.7209 1.66679 17.4999 1.66679H2.49998C2.27897 1.66679 2.06701 1.75459 1.91073 1.91087C1.75445 2.06715 1.66666 2.27911 1.66666 2.50012V17.4999C1.66666 17.5275 1.66803 17.555 1.67073 17.5822Z" fill="#19BBB9" />
            </g>
            <defs>
                <clipPath id="clip0_1228_27713">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    },
    {
        type: "consent", label: "Consent", icon: "add-arrow.svg", svg: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M1 3C0.447715 3 0 3.44772 0 4V14C0 14.5523 0.447715 15 1 15H13.4032C13.1538 14.4139 13.0173 13.7824 13.0122 13.1355L13 11.6077C12.9983 11.3868 13.1388 11.192 13.3497 11.1231C13.874 10.9519 14.3281 10.6398 14.6632 10.2205C14.7616 10.0973 14.9238 10.023 15.097 10.0218L18.1986 10H18.2029C18.3746 10 18.5363 10.0719 18.6359 10.1928C18.9776 10.6074 19.4369 10.9131 19.9639 11.0768C19.9762 11.0806 19.9882 11.0848 20 11.0894V4C20 3.44772 19.5523 3 19 3H1Z" fill="#2B9BC8" />
            <path d="M5.00037 8.23768C5.97249 8.23768 6.7604 7.44962 6.7604 6.47765C6.7604 5.50568 5.97234 4.71777 5.00037 4.71777C4.0284 4.71777 3.24005 5.50583 3.24005 6.4778C3.24005 7.44977 4.0284 8.23768 5.00037 8.23768ZM5.74695 8.35764H4.2535C3.01091 8.35764 2 9.3687 2 10.6113V12.4377L2.00464 12.4663L2.13044 12.5057C3.31628 12.8762 4.3465 12.9997 5.19447 12.9997C6.85071 12.9997 7.8107 12.5275 7.86985 12.4974L7.98742 12.438H8V10.6113C8.00045 9.3687 6.98954 8.35764 5.74695 8.35764Z" fill="#F5F5F5" />
            <rect x="10" y="5" width="8" height="1" rx="0.5" fill="#F5F5F5" />
            <rect x="10" y="7" width="8" height="1" rx="0.5" fill="#F5F5F5" />
            <path fillRule="evenodd" clipRule="evenodd" d="M19.3332 13.1882C19.3438 14.5496 18.6007 15.8309 17.3941 16.5321L16.8964 16.8214C16.7752 16.8918 16.624 16.8929 16.5018 16.8243L15.9996 16.5422C14.7822 15.8586 14.0194 14.5881 14.0089 13.2267L14 12.0808C13.9987 11.9151 14.1009 11.769 14.2543 11.7174C14.6356 11.589 14.9659 11.3549 15.2096 11.0404C15.2812 10.948 15.3991 10.8923 15.5251 10.8914L17.7808 10.875H17.784C17.9088 10.875 18.0264 10.9289 18.0988 11.0196C18.3474 11.3305 18.6814 11.5598 19.0647 11.6826C19.2187 11.732 19.3231 11.8764 19.3244 12.0421L19.3332 13.1882ZM15.9539 14.6449C16.1416 14.8269 16.4379 14.8336 16.6336 14.6601L18.525 12.9834C18.6547 12.8683 18.6562 12.6663 18.5282 12.5493C18.418 12.4486 18.2494 12.4473 18.1376 12.5464L16.3074 14.1688L15.5314 13.4174C15.4235 13.3129 15.2542 13.3067 15.1389 13.403C15.0052 13.5147 14.9977 13.7178 15.1228 13.8391L15.9539 14.6449Z" fill="#2B9BC8" />
        </svg>
    },


];

