import React, { useEffect, useState } from "react";
import { surveyAxiosInstance } from "../CommonFunction";
import { surveyEndpoints } from "../../SurveyEndpoints/SurveyEndpoints";
import { Accordion, Button, Col, Dropdown, Row, Table } from "react-bootstrap";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";

const SurveyExport = ({ survey_id, unique_code }) => {
  const [usersData, setUsersData] = useState([]);
  const [questions, setQuestions] = useState([]);

  // Fetch users and questions from your API

  const fetchData = async () => {
    try {
      const response = await surveyAxiosInstance.post(
        surveyEndpoints.DOWNLOAD_ALL_EXCEL_SHEET,
        { survey_id, unique_code }
      );

      // merging  user who gives answer on survey with their other vaues like completioin time and etc.
      const users = response.data.data.allData.map((item, index) => {
        return {
          ...item.user,
          answers: item.question,
        };
      });

      setUsersData(users); //setting all user with their given answer

      setQuestions(response.data.data.allQuestions); //setting all question of the survey 
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  function extractText(questions) {
    // Create a temporary DOM element to leverage the browser's HTML parser
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = questions;

    // Return the text content of the temporary element
    return tempDiv.textContent || tempDiv.innerText || "";
  }

  const exportSurveyDataToExcel = (users, allQuestions) => {
    // Prepare the header
    const headers = [
      "Name",
      "Email",
      "Country",
      "date",
      "Status",
      "completionTime",
      ...allQuestions.map((q) => extractText(q.question_text).trim()),
    ];

    // Prepare row data for each user
    const rowData = users.map((user) => {
      return [
        user.name || "N/A",
        user.email || "N/A",
        user.country || "N/A",
        user.date || "N/A",
        user.status || "N/A",
        user.completionTime || "N/A",
        ...allQuestions.map((q) => {
          const answer = user.answers.find((ans) => {
            if (ans.type === "matrix") {
              return ans.parent_id === q.question_id;
            } else {
              return ans.question_id === q.question_id;
            }
          });

         

          if (answer) {
            if (answer.type === "matrix") {
             
              // Handle matrix answers
              return (
                answer.question_detail
                  .map(
                    (detail) =>
                      `${detail.question_text}: ${detail.option_text.join(
                        ", "
                      )}`
                  )
                  .join(" | ") || "N/A"
              );
            } else if (answer.type === "freeText" || answer.type === "rating") {
              return answer.comment || "N/A";
            } else {
              if (answer?.question_detail?.length > 0) {
                const totalAnswers = answer?.question_detail
                  ?.map((item, index) => {
                    return item?.option_text || "N/A";
                  })
                  .toString();
                return totalAnswers || "N/A";
              } else {
                return answer.comment ? `other(${answer.comment})` : "N/A";
              }
            }
          }
          return "N/A";
        }),
      ];
    });

    // Create a new workbook and add the data
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...rowData]);

    // Calculate the maximum width for each column
    const maxLengths = headers.map((header) => header.length); // Start with header lengths

    rowData.forEach((row) => {
      row.forEach((cell, index) => {
        if (cell) {
          const cellLength = String(cell).length; // Get cell length
          if (cellLength > maxLengths[index]) {
            maxLengths[index] = cellLength; // Update max length
          }
        }
      });
    });

    // Set column widths
    const columnWidths = maxLengths.map((length) => ({ wpx: length * 10 })); // Adjust multiplier for width
    worksheet["!cols"] = columnWidths;

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Survey Results");

    // Export the file
    XLSX.writeFile(workbook, "survey_results.xlsx");
  };

  const handleExport = () => {
    exportSurveyDataToExcel(usersData, questions);
  };

  return (
    <Button
      title="Download Site Engagements"
      className="download filled"
      onClick={async (e) => {
        e.stopPropagation();

        handleExport();
      }}
    >
      Summary (Excel)
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {" "}
        <path
          d="M18.3335 13.125C18.1125 13.125 17.9005 13.2128 17.7442 13.3691C17.588 13.5254 17.5002 13.7373 17.5002 13.9583V15.1775C17.4995 15.7933 17.2546 16.3836 16.8192 16.819C16.3838 17.2544 15.7934 17.4993 15.1777 17.5H4.82266C4.2069 17.4993 3.61655 17.2544 3.18114 16.819C2.74573 16.3836 2.50082 15.7933 2.50016 15.1775V13.9583C2.50016 13.7373 2.41237 13.5254 2.25609 13.3691C2.0998 13.2128 1.88784 13.125 1.66683 13.125C1.44582 13.125 1.23385 13.2128 1.07757 13.3691C0.921293 13.5254 0.833496 13.7373 0.833496 13.9583V15.1775C0.834599 16.2351 1.25524 17.2492 2.00311 17.997C2.75099 18.7449 3.76501 19.1656 4.82266 19.1667H15.1777C16.2353 19.1656 17.2493 18.7449 17.9972 17.997C18.7451 17.2492 19.1657 16.2351 19.1668 15.1775V13.9583C19.1668 13.7373 19.079 13.5254 18.9228 13.3691C18.7665 13.2128 18.5545 13.125 18.3335 13.125Z"
          fill="#0066BE"
        ></path>{" "}
        <path
          d="M14.7456 9.20249C14.5893 9.04626 14.3774 8.9585 14.1564 8.9585C13.9355 8.9585 13.7235 9.04626 13.5673 9.20249L10.8231 11.9467L10.8333 1.77108C10.8333 1.55006 10.7455 1.3381 10.5893 1.18182C10.433 1.02554 10.221 0.937744 10 0.937744C9.77899 0.937744 9.56702 1.02554 9.41074 1.18182C9.25446 1.3381 9.16667 1.55006 9.16667 1.77108L9.15643 11.9467L6.41226 9.20249C6.25509 9.05069 6.04459 8.96669 5.82609 8.96859C5.60759 8.97049 5.39858 9.05813 5.24408 9.21264C5.08957 9.36715 5.00193 9.57615 5.00003 9.79465C4.99813 10.0131 5.08213 10.2236 5.23393 10.3808L9.40059 14.5475C9.478 14.6251 9.56996 14.6867 9.6712 14.7287C9.77245 14.7707 9.88098 14.7923 9.99059 14.7923C10.1002 14.7923 10.2087 14.7707 10.31 14.7287C10.4112 14.6867 10.5032 14.6251 10.5806 14.5475L14.7473 10.3808C14.9033 10.2243 14.9907 10.0123 14.9904 9.79131C14.9901 9.57034 14.902 9.35854 14.7456 9.20249Z"
          fill="#0066BE"
        ></path>{" "}
      </svg>
    </Button>
  );
};

export default SurveyExport;
