export const SidebarItems = [
    {
        type: "multiple", label: "Multiple Choice", icon: "add-arrow.svg", svg: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M3.375 2.25C3.375 2.87132 2.87132 3.375 2.25 3.375C1.62868 3.375 1.125 2.87132 1.125 2.25C1.125 1.62868 1.62868 1.125 2.25 1.125C2.87132 1.125 3.375 1.62868 3.375 2.25Z" fill="#8A4E9C" />
            <path fillRule="evenodd" clipRule="evenodd" d="M4.5 2.25C4.5 3.49264 3.49264 4.5 2.25 4.5C1.00736 4.5 0 3.49264 0 2.25C0 1.00736 1.00736 0 2.25 0C3.49264 0 4.5 1.00736 4.5 2.25ZM0.45 2.25C0.45 3.24411 1.25589 4.05 2.25 4.05C3.24411 4.05 4.05 3.24411 4.05 2.25C4.05 1.25589 3.24411 0.45 2.25 0.45C1.25589 0.45 0.45 1.25589 0.45 2.25Z" fill="#8A4E9C" />
            <path d="M6.75 1.125C6.12868 1.125 5.625 1.62868 5.625 2.25C5.625 2.87132 6.12868 3.375 6.75 3.375H16.875C17.4963 3.375 18 2.87132 18 2.25C18 1.62868 17.4963 1.125 16.875 1.125H6.75Z" fill="#8A4E9C" />
            <path fillRule="evenodd" clipRule="evenodd" d="M2.25 11.25C3.49264 11.25 4.5 10.2426 4.5 9C4.5 7.75736 3.49264 6.75 2.25 6.75C1.00736 6.75 0 7.75736 0 9C0 10.2426 1.00736 11.25 2.25 11.25ZM2.25 10.8C1.25589 10.8 0.45 9.99411 0.45 9C0.45 8.00589 1.25589 7.2 2.25 7.2C3.24411 7.2 4.05 8.00589 4.05 9C4.05 9.99411 3.24411 10.8 2.25 10.8Z" fill="#8A4E9C" />
            <path d="M6.75 7.875C6.12868 7.875 5.625 8.37868 5.625 9C5.625 9.62132 6.12868 10.125 6.75 10.125H16.875C17.4963 10.125 18 9.62132 18 9C18 8.37868 17.4963 7.875 16.875 7.875H6.75Z" fill="#8A4E9C" />
            <path fillRule="evenodd" clipRule="evenodd" d="M4.5 15.75C4.5 16.9926 3.49264 18 2.25 18C1.00736 18 0 16.9926 0 15.75C0 14.5074 1.00736 13.5 2.25 13.5C3.49264 13.5 4.5 14.5074 4.5 15.75ZM0.45 15.75C0.45 16.7441 1.25589 17.55 2.25 17.55C3.24411 17.55 4.05 16.7441 4.05 15.75C4.05 14.7559 3.24411 13.95 2.25 13.95C1.25589 13.95 0.45 14.7559 0.45 15.75Z" fill="#8A4E9C" />
            <path d="M5.625 15.75C5.625 15.1287 6.12868 14.625 6.75 14.625H16.875C17.4963 14.625 18 15.1287 18 15.75C18 16.3713 17.4963 16.875 16.875 16.875H6.75C6.12868 16.875 5.625 16.3713 5.625 15.75Z" fill="#8A4E9C" />
        </svg>
    },
    {
        type: "checkbox", label: "Checkbox", icon: "add-arrow.svg", svg: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M3.75 0C1.67893 0 0 1.67893 0 3.75V16.25C0 18.3211 1.67893 20 3.75 20H16.25C18.3211 20 20 18.3211 20 16.25V3.75C20 1.67893 18.3211 0 16.25 0H3.75ZM9.76847 13.5196C9.7911 13.4944 9.81508 13.4703 9.84039 13.4477C9.85548 13.4342 9.87033 13.4203 9.88493 13.406L15.2583 8.15197C15.7472 7.67386 15.7472 6.8987 15.2583 6.42059C14.7693 5.94248 13.9766 5.94249 13.4876 6.42059L9.16988 10.6424C8.96093 10.8468 8.62402 10.8525 8.40791 10.6554L6.4822 8.89956C5.97617 8.43816 5.18343 8.46523 4.71156 8.96002C4.23969 9.45481 4.26737 10.2299 4.77339 10.6913L7.97205 13.6079C8.47807 14.0693 9.27081 14.0423 9.74269 13.5475C9.75145 13.5383 9.76005 13.529 9.76847 13.5196Z" fill="#6E52A2" />
        </svg>
    },
    {
        type: "dropdown", label: "Dropdown", icon: "add-arrow.svg", svg: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path fillRule="evenodd" clipRule="evenodd" d="M0 1.88675C0 0.844725 0.839466 0 1.875 0H18.125C19.1605 0 20 0.844725 20 1.88675C20 2.92877 19.1605 3.77349 18.125 3.77349H1.875C0.839466 3.77349 0 2.92877 0 1.88675ZM14.9634 1.64799L16.0383 2.72968C16.0969 2.78855 16.1739 2.8181 16.2505 2.8181C16.327 2.8181 16.4041 2.78861 16.4626 2.72972L17.5375 1.64805C17.6548 1.53014 17.6548 1.33887 17.5375 1.22095C17.4203 1.10307 17.2304 1.10314 17.1132 1.22099L16.2505 2.08915L15.3877 1.22094C15.2705 1.10308 15.0806 1.10306 14.9634 1.22096C14.8462 1.33887 14.8462 1.53008 14.9634 1.64799Z" fill="#5058AA" />
            <path fillRule="evenodd" clipRule="evenodd" d="M0 7.29517C0 6.25315 0.839466 5.40843 1.875 5.40843H18.125C19.1605 5.40843 20 6.25315 20 7.29517C20 8.33719 19.1605 9.18192 18.125 9.18192H1.875C0.839466 9.18192 0 8.33719 0 7.29517ZM1.875 6.03734H18.125C18.8154 6.03734 19.375 6.60049 19.375 7.29517C19.375 7.98985 18.8154 8.553 18.125 8.553H1.875C1.18464 8.553 0.625 7.98985 0.625 7.29517C0.625 6.60049 1.18464 6.03734 1.875 6.03734Z" fill="#5058AA" />
            <path fillRule="evenodd" clipRule="evenodd" d="M1.875 10.8169C0.839466 10.8169 0 11.6616 0 12.7036C0 13.7456 0.839466 14.5903 1.875 14.5903H18.125C19.1605 14.5903 20 13.7456 20 12.7036C20 11.6616 19.1605 10.8169 18.125 10.8169H1.875ZM18.125 11.4458H1.875C1.18464 11.4458 0.625 12.0089 0.625 12.7036C0.625 13.3983 1.18464 13.9614 1.875 13.9614H18.125C18.8154 13.9614 19.375 13.3983 19.375 12.7036C19.375 12.0089 18.8154 11.4458 18.125 11.4458Z" fill="#5058AA" />
            <path fillRule="evenodd" clipRule="evenodd" d="M0 18.1133C0 17.0712 0.839466 16.2265 1.875 16.2265H18.125C19.1605 16.2265 20 17.0712 20 18.1133C20 19.1553 19.1605 20 18.125 20H1.875C0.839466 20 0 19.1553 0 18.1133ZM1.875 16.8554H18.125C18.8154 16.8554 19.375 17.4186 19.375 18.1133C19.375 18.8079 18.8154 19.3711 18.125 19.3711H1.875C1.18464 19.3711 0.625 18.8079 0.625 18.1133C0.625 17.4186 1.18464 16.8554 1.875 16.8554Z" fill="#5058AA" />
        </svg>
    },
    {
        type: "rating", label: "Rating", icon: "add-arrow.svg", svg: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <g clipPath="url(#clip0_1228_27637)">
                <path fillRule="evenodd" clipRule="evenodd" d="M16.8878 7.21979C16.9604 7.03806 17.2062 7.03806 17.2789 7.21979L17.9349 8.86038C17.9654 8.93683 18.0341 8.98909 18.1129 8.99592L19.8051 9.14246C19.9926 9.15869 20.0686 9.40357 19.926 9.53212L18.6392 10.6926C18.5793 10.7467 18.553 10.8312 18.5712 10.9119L18.9611 12.6431C19.0043 12.8348 18.8054 12.9862 18.6446 12.8839L17.1934 11.9605C17.1258 11.9175 17.0409 11.9175 16.9733 11.9605L15.522 12.8839C15.3613 12.9862 15.1624 12.8348 15.2056 12.6431L15.5955 10.9119C15.6136 10.8312 15.5874 10.7467 15.5274 10.6926L14.2407 9.53212C14.0981 9.40357 14.1741 9.15869 14.3615 9.14246L16.0537 8.99592C16.1326 8.98909 16.2012 8.93683 16.2318 8.86038L16.8878 7.21979ZM16.6187 9.01507L17.0833 7.853L17.548 9.01507C17.634 9.2302 17.8336 9.38995 18.077 9.41103L19.3189 9.51857L18.3602 10.3832C18.1851 10.541 18.1145 10.7803 18.1647 11.0034L18.4489 12.2655L17.4171 11.609C17.213 11.4791 16.9537 11.4791 16.7496 11.609L15.7177 12.2655L16.002 11.0034C16.0522 10.7803 15.9816 10.541 15.8065 10.3832L14.8478 9.51857L16.0897 9.41103C16.3331 9.38995 16.5327 9.2302 16.6187 9.01507Z" fill="#3D48AB" />
                <path d="M2.72111 7.21979C2.79377 7.03806 3.03957 7.03806 3.11223 7.21979L3.76819 8.86038C3.79876 8.93683 3.86742 8.98909 3.94628 8.99592L5.63848 9.14246C5.82593 9.15869 5.90189 9.40357 5.75935 9.53212L4.47255 10.6926C4.41259 10.7467 4.38636 10.8312 4.40453 10.9119L4.7944 12.6431C4.83759 12.8348 4.63873 12.9862 4.47798 12.8839L3.02673 11.9605C2.95911 11.9175 2.87423 11.9175 2.8066 11.9605L1.35536 12.8839C1.1946 12.9862 0.995744 12.8348 1.03893 12.6431L1.42881 10.9119C1.44697 10.8312 1.42075 10.7467 1.36078 10.6926L0.0739875 9.53212C-0.068552 9.40357 0.00740391 9.15869 0.194851 9.14246L1.88705 8.99592C1.96591 8.98909 2.03457 8.93683 2.06514 8.86038L2.72111 7.21979Z" fill="#3D48AB" />
                <path d="M9.80444 7.21979C9.8771 7.03806 10.1229 7.03806 10.1956 7.21979L10.8515 8.86038C10.8821 8.93683 10.9508 8.98909 11.0296 8.99592L12.7218 9.14246C12.9093 9.15869 12.9852 9.40357 12.8427 9.53212L11.5559 10.6926C11.4959 10.7467 11.4697 10.8312 11.4879 10.9119L11.8777 12.6431C11.9209 12.8348 11.7221 12.9862 11.5613 12.8839L10.1101 11.9605C10.0424 11.9175 9.95756 11.9175 9.88993 11.9605L8.43869 12.8839C8.27793 12.9862 8.07908 12.8348 8.12226 12.6431L8.51214 10.9119C8.53031 10.8312 8.50408 10.7467 8.44411 10.6926L7.15732 9.53212C7.01478 9.40357 7.09074 9.15869 7.27818 9.14246L8.97038 8.99592C9.04924 8.98909 9.11791 8.93683 9.14848 8.86038L9.80444 7.21979Z" fill="#3D48AB" />
            </g>
            <defs>
                <clipPath id="clip0_1228_27637">
                    <rect width="20" height="20" fill="white" />
                </clipPath>
            </defs>
        </svg>
    },
    {
        type: "matrix", label: "Matrix", icon: "add-arrow.svg", svg: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M3.75 2.5C3.75 3.19036 3.19036 3.75 2.5 3.75C1.80964 3.75 1.25 3.19036 1.25 2.5C1.25 1.80964 1.80964 1.25 2.5 1.25C3.19036 1.25 3.75 1.80964 3.75 2.5Z" fill="#045FAE" />
            <path fillRule="evenodd" clipRule="evenodd" d="M5 2.5C5 3.88071 3.88071 5 2.5 5C1.11929 5 0 3.88071 0 2.5C0 1.11929 1.11929 0 2.5 0C3.88071 0 5 1.11929 5 2.5ZM0.5 2.5C0.5 3.60457 1.39543 4.5 2.5 4.5C3.60457 4.5 4.5 3.60457 4.5 2.5C4.5 1.39543 3.60457 0.5 2.5 0.5C1.39543 0.5 0.5 1.39543 0.5 2.5Z" fill="#045FAE" />
            <path fillRule="evenodd" clipRule="evenodd" d="M2.5 12.5C3.88071 12.5 5 11.3807 5 10C5 8.61929 3.88071 7.5 2.5 7.5C1.11929 7.5 0 8.61929 0 10C0 11.3807 1.11929 12.5 2.5 12.5ZM2.5 12C1.39543 12 0.5 11.1046 0.5 10C0.5 8.89543 1.39543 8 2.5 8C3.60457 8 4.5 8.89543 4.5 10C4.5 11.1046 3.60457 12 2.5 12Z" fill="#045FAE" />
            <path fillRule="evenodd" clipRule="evenodd" d="M5 17.5C5 18.8807 3.88071 20 2.5 20C1.11929 20 0 18.8807 0 17.5C0 16.1193 1.11929 15 2.5 15C3.88071 15 5 16.1193 5 17.5ZM0.5 17.5C0.5 18.6046 1.39543 19.5 2.5 19.5C3.60457 19.5 4.5 18.6046 4.5 17.5C4.5 16.3954 3.60457 15.5 2.5 15.5C1.39543 15.5 0.5 16.3954 0.5 17.5Z" fill="#045FAE" />
            <path fillRule="evenodd" clipRule="evenodd" d="M10 5C11.3807 5 12.5 3.88071 12.5 2.5C12.5 1.11929 11.3807 0 10 0C8.61929 0 7.5 1.11929 7.5 2.5C7.5 3.88071 8.61929 5 10 5ZM10 4.5C8.89543 4.5 8 3.60457 8 2.5C8 1.39543 8.89543 0.5 10 0.5C11.1046 0.5 12 1.39543 12 2.5C12 3.60457 11.1046 4.5 10 4.5Z" fill="#045FAE" />
            <path d="M10 11.25C10.6904 11.25 11.25 10.6904 11.25 10C11.25 9.30964 10.6904 8.75 10 8.75C9.30964 8.75 8.75 9.30964 8.75 10C8.75 10.6904 9.30964 11.25 10 11.25Z" fill="#045FAE" />
            <path fillRule="evenodd" clipRule="evenodd" d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5ZM10 12C8.89543 12 8 11.1046 8 10C8 8.89543 8.89543 8 10 8C11.1046 8 12 8.89543 12 10C12 11.1046 11.1046 12 10 12Z" fill="#045FAE" />
            <path d="M11.25 17.5C11.25 18.1904 10.6904 18.75 10 18.75C9.30964 18.75 8.75 18.1904 8.75 17.5C8.75 16.8096 9.30964 16.25 10 16.25C10.6904 16.25 11.25 16.8096 11.25 17.5Z" fill="#045FAE" />
            <path fillRule="evenodd" clipRule="evenodd" d="M10 20C11.3807 20 12.5 18.8807 12.5 17.5C12.5 16.1193 11.3807 15 10 15C8.61929 15 7.5 16.1193 7.5 17.5C7.5 18.8807 8.61929 20 10 20ZM10 19.5C8.89543 19.5 8 18.6046 8 17.5C8 16.3954 8.89543 15.5 10 15.5C11.1046 15.5 12 16.3954 12 17.5C12 18.6046 11.1046 19.5 10 19.5Z" fill="#045FAE" />
            <path fillRule="evenodd" clipRule="evenodd" d="M17.5 5C18.8807 5 20 3.88071 20 2.5C20 1.11929 18.8807 0 17.5 0C16.1193 0 15 1.11929 15 2.5C15 3.88071 16.1193 5 17.5 5ZM17.5 4.5C16.3954 4.5 15.5 3.60457 15.5 2.5C15.5 1.39543 16.3954 0.5 17.5 0.5C18.6046 0.5 19.5 1.39543 19.5 2.5C19.5 3.60457 18.6046 4.5 17.5 4.5Z" fill="#045FAE" />
            <path fillRule="evenodd" clipRule="evenodd" d="M20 10C20 11.3807 18.8807 12.5 17.5 12.5C16.1193 12.5 15 11.3807 15 10C15 8.61929 16.1193 7.5 17.5 7.5C18.8807 7.5 20 8.61929 20 10ZM15.5 10C15.5 11.1046 16.3954 12 17.5 12C18.6046 12 19.5 11.1046 19.5 10C19.5 8.89543 18.6046 8 17.5 8C16.3954 8 15.5 8.89543 15.5 10Z" fill="#045FAE" />
            <path fillRule="evenodd" clipRule="evenodd" d="M17.5 20C18.8807 20 20 18.8807 20 17.5C20 16.1193 18.8807 15 17.5 15C16.1193 15 15 16.1193 15 17.5C15 18.8807 16.1193 20 17.5 20ZM17.5 19.5C16.3954 19.5 15.5 18.6046 15.5 17.5C15.5 16.3954 16.3954 15.5 17.5 15.5C18.6046 15.5 19.5 16.3954 19.5 17.5C19.5 18.6046 18.6046 19.5 17.5 19.5Z" fill="#045FAE" />
        </svg>
    },
    {
        type: "freeText", label: "Free text", icon: "add-arrow.svg", svg: <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
            <path d="M11.25 18.8049V20H17.3572V18.8049L15.1252 18.6264V1.38736L17.3572 1.20879V0H11.25V1.20879L13.482 1.38736V18.6264L11.25 18.8049Z" fill="#0066BE" />
            <path d="M1.25 5.01567H11.25V15.047H1.25C0.559644 15.047 0 14.4856 0 13.7931V6.26959C0 5.57707 0.559644 5.01567 1.25 5.01567Z" fill="#0066BE" />
            <path d="M18.75 15.047H17.5V5.01567H18.75C19.4404 5.01567 20 5.57707 20 6.26959V13.7931C20 14.4856 19.4404 15.047 18.75 15.047Z" fill="#0066BE" />
        </svg>
    },
    
];