import React from 'react'
import { Col, Row } from 'react-bootstrap'

const CisStats = () => {
  return (
    <Col className="right-sidebar">
        <div className="custom-container">
          <Row>
            <div className="top-header">
                sdffjbjkwiooqwe ioqeqwe 
            </div>
         </Row>
        </div>
    </Col>
  )
}

export default CisStats