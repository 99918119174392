import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import { ENDPOINT } from "../../axios/apiConfig";
import axios from "axios";
import { loader } from "../../loader";
import moment from "moment";
import { getData, postData, postFormData } from "../../axios/apiHelper";

const Feedback = () => {
  const activeTab = useRef(1);
  const [data, setData] = useState([]);
  const [feedbackData, setFeedback] = useState([]);
  const [search, setSearch] = useState("");
  const [apiStatus, setApiStatus] = useState(true);
  const [sectionLoader, setSectionLoader] = useState(false);
  const [updateflag, setupdateFlag] = useState(0);

  useEffect(() => {
    setApiStatus(false);
    getFeedbackData();
  }, []);
  const handleTabChange = (event) => {
    setApiStatus(false);
    setFeedback([]);
    setData([]);
    setSearch("");
    activeTab.current = event;
    setSectionLoader(true);
    if (event == 1) {
      getFeedbackData();
    } else if (event == 2) {
      getCommentData();
    }
  };

  const getFeedbackData = async () => {
    try {
      // loader(true);
      setSectionLoader(true);
      // await axios.get(ENDPOINT.FEEDBACKLISTINGMESSAGE).then((response) => {
      //   setData(response?.data?.data);
      //   setFeedback(response?.data?.data);
      // });
      const response = await getData(ENDPOINT.FEEDBACKLISTINGMESSAGE);
      setData(response?.data?.data);
      setFeedback(response?.data?.data);
      setApiStatus(true);
      // loader(false);
      setSectionLoader(false);
    } catch (err) {
      console.log(err);
      setApiStatus(true);
      // loader(false);
      setSectionLoader(false);
    }
  };

  const getCommentData = async () => {
    try {
      setSectionLoader(true);
      const comments = await getData(ENDPOINT.COMMENTLISTING);
      setData(comments?.data?.data);
      setFeedback(comments?.data?.data);

      setApiStatus(true);
      setSectionLoader(false);
    } catch (err) {
      console.log(err);
      setApiStatus(true);
      setSectionLoader(false);
    }
  };

  const searchChange = (e) => {
    setSearch(e?.target?.value?.trim());
    if (e?.target?.value === "") {
      setFeedback(data);
      setSearch("");
    }
  };

  const submitHandler = (event) => {
    event.preventDefault();

    const filteredData = data?.filter((item) =>
      item?.message?.toLowerCase().includes(search?.toLowerCase())
    );
    setFeedback(filteredData);
    setApiStatus(true);
  };

  const handleOptionChange = async (event, feed_id, type) => {
    const lib_data_index = feedbackData.findIndex((el) => el?._id === feed_id);
    if (feedbackData?.[lib_data_index]) {
      try {
        feedbackData[lib_data_index].reply_status = event.target.checked;
        setFeedback(feedbackData);
        setupdateFlag(updateflag + 1);
        let body = {
          id: feed_id,
          status: event.target.checked,
        };
        if (type == "feedback") {
          await postData(ENDPOINT.FEEDBACKUPDATE, body);
        } else {
          await postData(ENDPOINT.QUESTIONUPDATE, body);
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  const getDownloadData = async () => {
    try {
      loader("show");
      let type = activeTab?.current == 1 ? "feedback" : "question";
      let payload = {
        type: type,
      };

      const res = await postFormData(ENDPOINT.FEEDBACKIMPORT, payload, {
        responseType: "blob",
      });
      const link = document.createElement("a");
      const url = URL.createObjectURL(res?.data);
      link.href = url;
      link.download = type + ".xlsx";
      link.click();
      loader("hide");
    } catch (err) {
      console.log(err);
      loader("hide");
    }
  };

  return (
    <>
      <div className="right-sidebar">
        <div className="page-top-nav smart_list_names">
          <div className="row justify-content-end align-items-center">
            <div className="col-12 col-md-11"></div>
          </div>
        </div>

        <section className="search-hcp smart-list-view">
          <div className="result-hcp-table">
            <div className="table-title">
              <h4>
                {activeTab.current == 1 ? "Total Feedbacks" : "Total Questions"}{" "}
                <span>| {data?.length}</span>
              </h4>
            </div>

            <div className="selected-hcp-list search_view">
              <div className="feedback-block">
                <div className="delivery-trends feedback">
                  <div className="delivery-trends-sticky">
                    <div className="tabs_content_load">
                      <Tabs
                        defaultActiveKey={activeTab.current}
                        onSelect={handleTabChange}
                      >
                        <Tab eventKey="1" title="User Feedback"></Tab>
                        <Tab eventKey="2" title="Content Questions"></Tab>
                      </Tabs>
                      {sectionLoader ? (
                        <div
                          className={
                            "loader tab-inside " + (sectionLoader ? "show" : "")
                          }
                          id="custom_loader"
                        >
                          <div className="loader_show">
                            <span className="loader-view"> </span>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    {data?.length ? (
                      <>
                        <div className="search-bar d-flex justify-content-end">
                          <div className="clear-search">
                            <button
                              className="btn print"
                              title="Download stats"
                              onClick={() => {
                                getDownloadData();
                              }}
                            >
                              <svg
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M18.3335 13.125C18.1125 13.125 17.9005 13.2128 17.7442 13.3691C17.588 13.5254 17.5002 13.7373 17.5002 13.9583V15.1775C17.4995 15.7933 17.2546 16.3836 16.8192 16.819C16.3838 17.2544 15.7934 17.4993 15.1777 17.5H4.82266C4.2069 17.4993 3.61655 17.2544 3.18114 16.819C2.74573 16.3836 2.50082 15.7933 2.50016 15.1775V13.9583C2.50016 13.7373 2.41237 13.5254 2.25609 13.3691C2.0998 13.2128 1.88784 13.125 1.66683 13.125C1.44582 13.125 1.23385 13.2128 1.07757 13.3691C0.921293 13.5254 0.833496 13.7373 0.833496 13.9583V15.1775C0.834599 16.2351 1.25524 17.2492 2.00311 17.997C2.75099 18.7449 3.76501 19.1656 4.82266 19.1667H15.1777C16.2353 19.1656 17.2493 18.7449 17.9972 17.997C18.7451 17.2492 19.1657 16.2351 19.1668 15.1775V13.9583C19.1668 13.7373 19.079 13.5254 18.9228 13.3691C18.7665 13.2128 18.5545 13.125 18.3335 13.125Z"
                                  fill="#0066BE"
                                />
                                <path
                                  d="M14.7456 9.20249C14.5893 9.04626 14.3774 8.9585 14.1564 8.9585C13.9355 8.9585 13.7235 9.04626 13.5673 9.20249L10.8231 11.9467L10.8333 1.77108C10.8333 1.55006 10.7455 1.3381 10.5893 1.18182C10.433 1.02554 10.221 0.937744 10 0.937744C9.77899 0.937744 9.56702 1.02554 9.41074 1.18182C9.25446 1.3381 9.16667 1.55006 9.16667 1.77108L9.15643 11.9467L6.41226 9.20249C6.25509 9.05069 6.04459 8.96669 5.82609 8.96859C5.60759 8.97049 5.39858 9.05813 5.24408 9.21264C5.08957 9.36715 5.00193 9.57615 5.00003 9.79465C4.99813 10.0131 5.08213 10.2236 5.23393 10.3808L9.40059 14.5475C9.478 14.6251 9.56996 14.6867 9.6712 14.7287C9.77245 14.7707 9.88098 14.7923 9.99059 14.7923C10.1002 14.7923 10.2087 14.7707 10.31 14.7287C10.4112 14.6867 10.5032 14.6251 10.5806 14.5475L14.7473 10.3808C14.9033 10.2243 14.9907 10.0123 14.9904 9.79131C14.9901 9.57034 14.902 9.35854 14.7456 9.20249Z"
                                  fill="#0066BE"
                                />
                              </svg>
                            </button>
                          </div>
                          <form
                            className="d-flex"
                            onSubmit={(e) => submitHandler(e)}
                          >
                            <input
                              className="form-control me-2"
                              type="search"
                              placeholder="Search"
                              aria-label="Search"
                              onChange={(e) => searchChange(e)}
                            />
                            {!search ? (
                              <button
                                className="btn btn-outline-success"
                                type="submit"
                              >
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M15.8045 14.862L11.2545 10.312C12.1359 9.22334 12.6665 7.84 12.6665 6.33334C12.6665 2.84134 9.82522 0 6.33325 0C2.84128 0 0 2.84131 0 6.33331C0 9.82531 2.84132 12.6667 6.33328 12.6667C7.83992 12.6667 9.22325 12.136 10.3119 11.2547L14.8619 15.8047C14.9919 15.9347 15.1625 16 15.3332 16C15.5039 16 15.6745 15.9347 15.8045 15.8047C16.0652 15.544 16.0652 15.1227 15.8045 14.862ZM6.33328 11.3333C3.57597 11.3333 1.33333 9.09066 1.33333 6.33331C1.33333 3.57597 3.57597 1.33331 6.33328 1.33331C9.0906 1.33331 11.3332 3.57597 11.3332 6.33331C11.3332 9.09066 9.09057 11.3333 6.33328 11.3333Z"
                                    fill="#97B6CF"
                                  ></path>
                                </svg>
                              </button>
                            ) : null}
                          </form>
                        </div>
                      </>
                    ) : null}
                  </div>
                  {(typeof feedbackData !== "undefined" &&
                    feedbackData?.length > 0) ||
                  updateflag ? (
                    feedbackData?.map((item, index) => (
                      <>
                        <div className="wrap_div">
                          {activeTab?.current == 1 ? (
                            <div
                              className={
                                item.reply_status
                                  ? "radio_btn div_checked"
                                  : "radio_btn"
                              }
                            >
                              <input
                                type="checkbox"
                                value={item._id}
                                name={item._id}
                                checked={item.reply_status}
                                onChange={(e) =>
                                  handleOptionChange(e, item._id, "feedback")
                                }
                              />
                              <span class="checkmark"></span>
                            </div>
                          ) : (
                            <div
                              className={
                                item.reply_status
                                  ? "radio_btn div_checked"
                                  : "radio_btn"
                              }
                            >
                              <input
                                type="checkbox"
                                value={item._id}
                                name={item._id}
                                checked={item.reply_status}
                                onChange={(e) =>
                                  handleOptionChange(e, item._id, "question")
                                }
                              />
                              <span class="checkmark"></span>
                            </div>
                          )}
                          <div
                            className={
                              activeTab?.current == 1
                                ? "timeline-block feedback"
                                : "timeline-block"
                            }
                            key={index}
                          >
                            <div className="timeline-block-head library">
                              <div className="timeline-block-title d-flex flex-column align-items-start">
                                <h6>
                                  <span>Email:</span>
                                  {item?.email}
                                </h6>
                                <h6>
                                  <span>Country:</span>
                                  {item?.country ? item?.country : "N/A"}
                                </h6>
                                <h6>
                                  <span>Site:</span>
                                  {item?.site_data ? item?.site_data : "N/A"}
                                </h6>
                                {activeTab?.current == 2 ? (
                                  <h6>
                                    <span>Article Title:</span>{" "}
                                    {item?.title ? item?.title : "N/A"}
                                  </h6>
                                ) : null}

                                <h6>
                                  <span>
                                    {activeTab?.current == 1 ? (
                                      <span>Feedback:</span>
                                    ) : (
                                      <span>Question:</span>
                                    )}
                                  </span>
                                  <strong>{item?.message}</strong>
                                </h6>
                              </div>
                              <div className="timeline-time-view">
                                <div className="timeline-time">
                                  {moment(item?.createdAt).format(
                                    "D MMMM YYYY"
                                  )}
                                </div>
                                |
                                <div className="timeline-timezone">
                                  {moment(item?.createdAt).format("h:mm A")}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ))
                  ) : (
                    <>
                      {apiStatus ? (
                        <h4 className="not-found" style={{ color: "#004A89" }}>
                          No Data Found
                        </h4>
                      ) : null}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};
export default Feedback;
