import "./App.scss";

function App() {
  return (
    <>
     <div className="col right-sidebar default_page_not_found">
       <div className="coming-soon">
          <h2>✴ Home Page ✴</h2>
       </div>
      </div>      
    </>
  );
}
export default App;
