import React, { useEffect, useRef, useState } from "react";
import { Accordion, Button, Col, Row, Table } from "react-bootstrap";
import { useSidebar } from "../../../../CommonComponent/LoginLayout";
import { loader } from "../../../../../loader";
import { postData } from "../../../../../axios/apiHelper";
import { ENDPOINT } from "../../../../../axios/apiConfig";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { toast } from "react-toastify";

const AnalyticsAttendees = () => {
  const path_image = process.env.REACT_APP_ASSETS_PATH_INFORMED_DESIGN;
  const [search, setSearch] = useState("");
  const buttonRef = useRef(null);
  const filterRef = useRef(null);
  const [apifilterObject, setApifilterObject] = useState({});
  const [showFilter, setShowFilter] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [appliedFilter, setAppliedFilter] = useState({});
  const [filterObject, setFilterObject] = useState({});
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  
  const [currentIndex, setCurrentIndex] = useState(-1);

  const { eventIdContext } = useSidebar();
  const localStorageEvent = JSON.parse(localStorage.getItem("EventIdContext"));
  const eventId = eventIdContext?.eventId || localStorageEvent?.eventId

  const [attendeesData, setAttendeesData] = useState([]);
  const [attendeesDataDropdown, setAttendeesDataDropdown] = useState(null);
  const [attendeesDataOriginal, setAttendeesDataOriginal] = useState([]);
  const [sortBy, setSortBy] = useState('name');
  const [sortOrder, setSortOrder] = useState('asc');
  const [apiStatus, setApiStatus] = useState(false);

  useEffect(() => {
    const fetchAnalyticsData = async () => {
      try {
        loader("show");
        const body = { eventId };
        const response = await postData(ENDPOINT.ANALYTIC_ATTENDEES_DATA, body);
        const result = response?.data?.data;
        setFilterData(result?.filterObject);

        setAttendeesData(result?.attendeesData);
        setAttendeesDataOriginal(result?.attendeesData);

      } catch (error) {
        console.error("Error fetching analytics data:", error);
      } finally {
        setApiStatus(true)
        loader("hide");

      }
    };

    fetchAnalyticsData();
  }, [eventId]);
  const customLoader = (functionName, e = null) => {
    if (e != null) {
      e.preventDefault()
    }
    loader("show");
    setTimeout(() => {
      functionName(e);
    }, 300);
  };

  const clearFilter = () => {
    setAppliedFilter({});
    setApifilterObject({});
    setFilterObject({});
    setAttendeesData(attendeesDataOriginal);
    setShowFilter(false);
    setIsFilterApplied(false);
    loader("hide");



  };
  const applyFilter = (updatedFilter = null) => {
    
    let filters = updatedFilter ?? appliedFilter;
    // setIsFilterApplied(Object.keys(filters).length==0);
    const filteredData = attendeesDataOriginal.filter((item) => {
      for (const key in filters) {
        const filterValues = filters[key];
        if (filterValues.length === 0) {
          continue;
        }
        let isMatch = false;

        if (filterValues.length > 1) {
          isMatch = filterValues.some(value => {
            if (value == "All") return true
            return item[key] === value
          });
        } else {
          isMatch = filterValues[0] == "All" ? true : item[key] === filterValues[0];
        }

        if (!isMatch) {
          return false;
        }
      }

      return true;

    });
    setAttendeesData(filteredData);
    setFilterObject(filters);
    setShowFilter(false);
    loader("hide");


  };
  const handleOnFilterChange = (e, item, index, key, data = []) => {

    let newObj = { ...appliedFilter };
    let newApiFilterObject = { ...apifilterObject };
    const radioKeys = ["Attended", "Registered", "Poll Participate", "Asked Question"]    // Initialize arrays if they don't exist
    newObj[key] = newObj[key] || [];
    newApiFilterObject[key] = newApiFilterObject[key] || [];

    // Check if checkbox is checked
    if (e?.target?.checked) {
      // Special handling for certain keys
      if (radioKeys.includes(key)) {
        newObj[key] = [item];
        newApiFilterObject[key] = e?.target?.value;
      } else {
        newObj[key]?.push(item);
        newApiFilterObject[key]?.push(e?.target?.value);
      }
    } else { // Checkbox is unchecked
      // Special handling for certain keys
      if (radioKeys.includes(key)) {
        newObj[key] = [];
        newApiFilterObject[key] = [];
      } else {
        const itemIndex = newObj[key]?.indexOf(item);
        if (itemIndex > -1) {
          newObj[key]?.splice(itemIndex, 1);
          if (newObj[key]?.length === 0) {
            delete newObj[key];
          }
        }
        const valueIndex = newApiFilterObject[key]?.indexOf(e.target.value);
        if (valueIndex > -1) {
          newApiFilterObject[key]?.splice(valueIndex, 1);
          if (newApiFilterObject[key]?.length === 0) {
            delete newApiFilterObject[key];
          }
        }
      }
    }

    // Update state
    setAppliedFilter(newObj);
    setApifilterObject(newApiFilterObject);
  };

  const searchChange = (e) => {
    setShowFilter(false);

    setSearch(e?.target?.value);

    if (e?.target?.value === "") {
      setAttendeesData(attendeesDataOriginal);
    }
  };

  const submitSearchHandler = (event) => {
    event.preventDefault();
    let searchData = attendeesDataOriginal?.filter((item) =>
      item?.name?.toLowerCase().includes(search.toLowerCase()) ||
      item?.email?.toLowerCase().includes(search.toLowerCase()) ||
      item?.country?.toLowerCase().includes(search.toLowerCase()) ||
      item?.region?.toLowerCase().includes(search.toLowerCase())
    );
    setShowFilter(false);

    setAttendeesData(searchData);
    loader("hide");

  };

  const handleDropdown = async (userId, index) => {
    loader("show");
    setShowFilter(false);
    if (currentIndex != index) {
      const body = { eventId, userId };
      const response = await postData(
        ENDPOINT.ANALYTIC_ATTENDEES_DROPDOWN_DATA,
        body
      );
      const result = response?.data?.data;
      setAttendeesDataDropdown(result);
      setCurrentIndex(index);
    } else {
      setAttendeesDataDropdown(null);
      setCurrentIndex(-1);
    }
    loader("hide");
  };

  const downloadExcel = (data, name) => {
    loader("show")
    setShowFilter(false);
    setTimeout(() => {
      try {
        if (data?.length == 0) {
          toast.warning("No data found");
          return;
        }
        data = data?.map((item, index) => {
          let finalData = {};

          finalData.Name = item?.name ? item?.name.trim() : "Anonymous";

          finalData.Email = item?.email ? item?.email.trim() : "N/A";
          finalData.Region = item?.region ? item?.region.trim() : "N/A";
          finalData.Country = item?.country ? item?.country.trim() : "N/A";
          // finalData.Registered = item?.register_time
          //   ? item?.register_time.trim()
          //   : "N/A";

          if (item?.liveSpendTime != undefined) {

            finalData["Live Spend Time"] = item?.liveSpendTime
              ? item?.liveSpendTime
              : "N/A";
          }
          if (item?.askedQuestion != undefined) {

            finalData["Asked Question"] = item?.askedQuestion
              ? item?.askedQuestion.trim()
              : "N/A";
          }
          if (item?.pollParticipate != undefined) {

            finalData["Poll Participate"] = item?.pollParticipate
              ? item?.pollParticipate.trim()
              : "N/A";
          }

          return finalData;
        });
        const worksheet = XLSX.utils.json_to_sheet(data);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
        const excelBuffer = XLSX.write(workbook, {
          bookType: "xlsx",
          type: "array",
        });
        const blob = new Blob([excelBuffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
        });
        saveAs(blob, `${name}_data.xlsx`);
      } catch (error) {
        console.error(
          "An error occurred while downloading the Excel file:",
          error
        );
      } loader("hide")

    }, 500)
  };
  const handleSort = (key) => {
    setSortBy(key);
    setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
  };

  const sortData = (data, key, order) => {
    return data.sort((a, b) => {
      let valueA = a[key];
      let valueB = b[key];
      if (key == "register_time") {
        valueA = new Date(a[key]);;
        valueB = new Date(b[key]);
        return order === 'asc' ? valueA - valueB : valueB - valueA;
      }

      // Handle different data types (numbers, strings)
      if (typeof valueA === 'number' && typeof valueB === 'number') {

        return order === 'asc' ? valueA - valueB : valueB - valueA;
      } else {
        return order === 'asc'
          ? valueA?.localeCompare(valueB) // Handle string sorting with locale awareness
          : valueB?.localeCompare(valueA);
      }
    });
  };
  const removeindividualfilter = (key, item) => {
    loader("show");
    let updatedFilter = JSON.parse(JSON.stringify(appliedFilter))
    let index = updatedFilter[key]?.indexOf(item)
    if (index > -1) {
      updatedFilter[key]?.splice(index, 1)
      if (updatedFilter[key]?.length == 0) {
        delete updatedFilter[key]
      }
    }
    setAppliedFilter(updatedFilter)
    applyFilter(updatedFilter)
  }
  const renderSortButton = (key) => (
    <button
      className={`event_sort_btn ${sortBy === key ? (sortOrder === "asc" ? "svg_asc" : "svg_active") : ""}`}
      onClick={() => handleSort(key)}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
        <g clipPath="url(#clip0_3722_6611)">
          <path d="M7.00015 5.19137L4.3311 7.84461C4.28138 7.89413 4.22222 7.93328 4.15708 7.95976C4.02649 8.01341 3.87983 8.01341 3.74925 7.95976C3.6841 7.93328 3.62494 7.89413 3.57522 7.84461L0.90617 5.19137C0.806076 5.09173 0.7499 4.95664 0.75 4.81582C0.7501 4.67501 0.806468 4.54 0.906704 4.4405C1.00694 4.341 1.14283 4.28516 1.28449 4.28526C1.42614 4.28536 1.56195 4.34139 1.66205 4.44103L3.41988 6.18845L3.41357 0.530648C3.41357 0.389912 3.46981 0.254939 3.56992 0.155423C3.67003 0.0559068 3.8058 4.76837e-07 3.94738 4.76837e-07C4.08895 4.76837e-07 4.22473 0.0559068 4.32484 0.155423C4.42495 0.254939 4.48119 0.389912 4.48119 0.530648L4.48751 6.18845L6.24534 4.44103C6.34602 4.34437 6.48086 4.29088 6.62083 4.29209C6.76079 4.2933 6.89468 4.34911 6.99365 4.44749C7.09262 4.54588 7.14876 4.67897 7.14998 4.81811C7.1512 4.95724 7.09739 5.09129 7.00015 5.19137Z" fill="#97B6CF" />
        </g>
        <defs>
          <clipPath id="clip0_3722_6611">
            <rect width="8" height="8" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </button>
  );

  return (
    <>
      <Col className="right-sidebar custom-change">
        <div className="custom-container">
          {apiStatus && <Row>
            <div className="top-header regi-web sticky">
              <div className="page-title d-flex flex-column align-items-start">
                <h2>Attendees</h2>
              </div>
              <div className="top-right-action">
                <div className="search-bar">
                  <form className="d-flex" onSubmit={(e) => customLoader(submitSearchHandler, e)}>
                    <input
                      className="form-control me-2"
                      type="search"
                      placeholder="Search"
                      aria-label="Search"
                      id="email_search"
                      onFocus={() => setShowFilter(false)}
                      onChange={(e) => searchChange(e)}
                    />
                    <button className="btn btn-outline" type="submit">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.8045 14.862L11.2545 10.312C12.1359 9.22334 12.6665 7.84 12.6665 6.33334C12.6665 2.84134 9.82522 0 6.33325 0C2.84128 0 0 2.84131 0 6.33331C0 9.82531 2.84132 12.6667 6.33328 12.6667C7.83992 12.6667 9.22325 12.136 10.3119 11.2547L14.8619 15.8047C14.9919 15.9347 15.1625 16 15.3332 16C15.5039 16 15.6745 15.9347 15.8045 15.8047C16.0652 15.544 16.0652 15.1227 15.8045 14.862ZM6.33328 11.3333C3.57597 11.3333 1.33333 9.09066 1.33333 6.33331C1.33333 3.57597 3.57597 1.33331 6.33328 1.33331C9.0906 1.33331 11.3332 3.57597 11.3332 6.33331C11.3332 9.09066 9.09057 11.3333 6.33328 11.3333Z"
                          fill="#97B6CF"
                        />
                      </svg>
                    </button>
                  </form>
                </div>
                <div className="filter-by nav-item dropdown">
                  <button
                    ref={buttonRef}
                    className={
                      Object.keys(apifilterObject)?.length
                        ? "btn btn-secondary dropdown filter_applied"
                        : "btn btn-secondary dropdown"
                    }
                    type="button"
                    id="dropdownMenuButton2"
                    onClick={() => setShowFilter((showFilter) => !showFilter)}
                  >
                    Filter By
                    {showFilter ? (
                      <svg
                        className="close-arrow"
                        width="13"
                        height="12"
                        viewBox="0 0 13 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          width="2.09896"
                          height="15.1911"
                          rx="1.04948"
                          transform="matrix(0.720074 0.693897 -0.720074 0.693897 11.0977 0)"
                          fill="#0066BE"
                        />
                        <rect
                          width="2.09896"
                          height="15.1911"
                          rx="1.04948"
                          transform="matrix(0.720074 -0.693897 0.720074 0.693897 0 1.45898)"
                          fill="#0066BE"
                        />
                      </svg>
                    ) : (
                      <svg
                        className="filter-arrow"
                        width="16"
                        height="14"
                        viewBox="0 0 16 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.615385 2.46154H3.07692C3.07692 3.14031 3.62892 3.69231 4.30769 3.69231H5.53846C6.21723 3.69231 6.76923 3.14031 6.76923 2.46154H15.3846C15.7243 2.46154 16 2.18646 16 1.84615C16 1.50585 15.7243 1.23077 15.3846 1.23077H6.76923C6.76923 0.552 6.21723 0 5.53846 0H4.30769C3.62892 0 3.07692 0.552 3.07692 1.23077H0.615385C0.275692 1.23077 0 1.50585 0 1.84615C0 2.18646 0.275692 2.46154 0.615385 2.46154Z"
                          fill="#97B6CF"
                        ></path>
                        <path
                          d="M15.3846 6.15362H11.6923C11.6923 5.47485 11.1403 4.92285 10.4615 4.92285H9.23077C8.552 4.92285 8 5.47485 8 6.15362H0.615385C0.275692 6.15362 0 6.4287 0 6.76901C0 7.10931 0.275692 7.38439 0.615385 7.38439H8C8 8.06316 8.552 8.61516 9.23077 8.61516H10.4615C11.1403 8.61516 11.6923 8.06316 11.6923 7.38439H15.3846C15.7243 7.38439 16 7.10931 16 6.76901C16 6.4287 15.7243 6.15362 15.3846 6.15362Z"
                          fill="#97B6CF"
                        ></path>
                        <path
                          d="M15.3846 11.077H6.76923C6.76923 10.3982 6.21723 9.84619 5.53846 9.84619H4.30769C3.62892 9.84619 3.07692 10.3982 3.07692 11.077H0.615385C0.275692 11.077 0 11.352 0 11.6923C0 12.0327 0.275692 12.3077 0.615385 12.3077H3.07692C3.07692 12.9865 3.62892 13.5385 4.30769 13.5385H5.53846C6.21723 13.5385 6.76923 12.9865 6.76923 12.3077H15.3846C15.7243 12.3077 16 12.0327 16 11.6923C16 11.352 15.7243 11.077 15.3846 11.077Z"
                          fill="#97B6CF"
                        ></path>
                      </svg>
                    )}
                  </button>
                  {showFilter && (
                    <div
                      ref={filterRef}
                      className="dropdown-menu filter-options"
                      aria-labelledby="dropdownMenuButton2"
                    >
                      <h4>Filter By</h4>
                      <Accordion defaultActiveKey="0" flush>
                        {Object.keys(filterData)?.map(function (key, index) {
                          return (
                            <>
                              {filterData[key]?.length > 0 ? (
                                <Accordion.Item
                                  className={
                                    key == "role" ? "card upper" : "card"
                                  }
                                  eventKey={index}
                                >
                                  <Accordion.Header className="card-header">
                                    {key}
                                  </Accordion.Header>
                                  <Accordion.Body className="card-body">
                                    <ul>
                                      {filterData[key]?.length
                                        ? filterData[key]?.map(
                                          (item, index) => (
                                            <li key={index}>
                                              {item != "" ? (
                                                <label className="select-multiple-option">
                                                  <input
                                                    type={"checkbox"}
                                                    id={`custom-checkbox-tags-${index}`}
                                                    value={
                                                      typeof item ==
                                                        "object"
                                                        ? item?.title
                                                        : item
                                                    }
                                                    name={key}
                                                    checked={
                                                      typeof item ==
                                                        "object"
                                                        ? appliedFilter[
                                                          key
                                                        ]?.includes(
                                                          item.id
                                                        )
                                                          ? true
                                                          : false
                                                        : appliedFilter[
                                                          key
                                                        ]?.includes(item)
                                                          ? true
                                                          : false
                                                    }
                                                    onChange={(e) =>
                                                      handleOnFilterChange(
                                                        e,
                                                        typeof item ==
                                                          "object"
                                                          ? item.id
                                                          : item,
                                                        index,
                                                        key,
                                                        [...filterData[key]]
                                                      )
                                                    }
                                                  />
                                                  {typeof item == "object"
                                                    ? item?.title
                                                    : item}
                                                  <span className="checkmark"></span>
                                                </label>
                                              ) : null}
                                            </li>
                                          )
                                        )
                                        : null}
                                    </ul>
                                  </Accordion.Body>
                                </Accordion.Item>
                              ) : null}
                            </>
                          );
                        })}
                      </Accordion>

                      <div className="filter-footer">
                        <Button
                          className="btn btn-primary btn-bordered"
                          onClick={() => customLoader(clearFilter)}
                        >
                          Clear
                        </Button>
                        <Button
                          className="btn btn-primary btn-filled"
                          onClick={(e) => customLoader(applyFilter)}
                        >
                          Apply
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
                <div className="clear-search">
                  <Button
                    title="Download stats"
                    className="download"
                    onClick={() => downloadExcel(attendeesData, "Attendees data")}
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M18.3335 13.125C18.1125 13.125 17.9005 13.2128 17.7442 13.3691C17.588 13.5254 17.5002 13.7373 17.5002 13.9583V15.1775C17.4995 15.7933 17.2546 16.3836 16.8192 16.819C16.3838 17.2544 15.7934 17.4993 15.1777 17.5H4.82266C4.2069 17.4993 3.61655 17.2544 3.18114 16.819C2.74573 16.3836 2.50082 15.7933 2.50016 15.1775V13.9583C2.50016 13.7373 2.41237 13.5254 2.25609 13.3691C2.0998 13.2128 1.88784 13.125 1.66683 13.125C1.44582 13.125 1.23385 13.2128 1.07757 13.3691C0.921293 13.5254 0.833496 13.7373 0.833496 13.9583V15.1775C0.834599 16.2351 1.25524 17.2492 2.00311 17.997C2.75099 18.7449 3.76501 19.1656 4.82266 19.1667H15.1777C16.2353 19.1656 17.2493 18.7449 17.9972 17.997C18.7451 17.2492 19.1657 16.2351 19.1668 15.1775V13.9583C19.1668 13.7373 19.079 13.5254 18.9228 13.3691C18.7665 13.2128 18.5545 13.125 18.3335 13.125Z"
                        fill="#0066BE"
                      ></path>
                      <path
                        d="M14.7456 9.20249C14.5893 9.04626 14.3774 8.9585 14.1564 8.9585C13.9355 8.9585 13.7235 9.04626 13.5673 9.20249L10.8231 11.9467L10.8333 1.77108C10.8333 1.55006 10.7455 1.3381 10.5893 1.18182C10.433 1.02554 10.221 0.937744 10 0.937744C9.77899 0.937744 9.56702 1.02554 9.41074 1.18182C9.25446 1.3381 9.16667 1.55006 9.16667 1.77108L9.15643 11.9467L6.41226 9.20249C6.25509 9.05069 6.04459 8.96669 5.82609 8.96859C5.60759 8.97049 5.39858 9.05813 5.24408 9.21264C5.08957 9.36715 5.00193 9.57615 5.00003 9.79465C4.99813 10.0131 5.08213 10.2236 5.23393 10.3808L9.40059 14.5475C9.478 14.6251 9.56996 14.6867 9.6712 14.7287C9.77245 14.7707 9.88098 14.7923 9.99059 14.7923C10.1002 14.7923 10.2087 14.7707 10.31 14.7287C10.4112 14.6867 10.5032 14.6251 10.5806 14.5475L14.7473 10.3808C14.9033 10.2243 14.9907 10.0123 14.9904 9.79131C14.9901 9.57034 14.902 9.35854 14.7456 9.20249Z"
                        fill="#0066BE"
                      ></path>
                    </svg>
                  </Button>
                </div>
              </div>
            </div>
            <div className="search-hcp smart-list-view anaytics-attended">
              <div className="result-hcp-table">
                <div className="table-title">
                  <h4>Total Attendees |{" "}<span>{attendeesData?.length || 0}</span></h4>
                </div>
                {(Object.keys(appliedFilter)?.length > 0 )? (
                  <div className="apply-filter">
                    <div className="filter-block">
                      <div className="filter-block-left full">
                        {Object.keys(appliedFilter)?.map((key, index) => {
                          return (<>
                            {appliedFilter[key]?.length ? (
                              <div className="filter-div">
                                <div className="filter-div-title">
                                  <span>{key} |</span>
                                </div>
                                <div className="filter-div-list">
                                  {appliedFilter[key]?.map((item, index) => (
                                    <div className="filter-result"
                                      id={item}
                                      rt={index} >
                                      {item}
                                      <img
                                        src={
                                          path_image + "filter-close.svg"
                                        }
                                        onClick={(event) => {
                                          removeindividualfilter(key, item);
                                        }}
                                        alt="Close-filter"
                                      />
                                    </div>

                                  ))}
                                </div>
                              </div>
                            ) : ""}
                          </>)
                        })}
                      </div>
                      <div className="clear-filter">
                        <Button
                          className="btn btn-outline-primary btn-bordered"
                          onClick={() => customLoader(clearFilter)}
                        >
                          Remove All
                        </Button>
                      </div>
                    </div>
                  </div>
                ) : ""}
              </div>


              <Table className="attended-table" id="individual_completion">
                <thead>
                  <tr>
                    {[
                      { name: 'Name', key: 'name' },
                      { name: 'Email', key: 'email' },
                      { name: 'Region', key: 'region' },
                      { name: 'Country', key: 'country' },
                      { name: 'Live spend time', key: 'liveSpendTime' },
                      { name: 'Asked question', key: 'askedQuestion' },
                      { name: 'Poll participate', key: 'pollParticipate' },
                    ].map((column) => (
                      <th scope="col" className="sort_option" key={column.key}>
                        <span onClick={() => handleSort(column.key)}>
                          {column.name}
                          {renderSortButton(column.key)}
                        </span>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {attendeesData?.length ? (
                    // attendeesData.map((user, index) => (
                    sortData(attendeesData, sortBy, sortOrder).map((user, index) => (
                      <>
                        <tr
                          key={index}
                          className={
                            "view " + (currentIndex === index ? "show" : "")
                          }
                          onClick={() => handleDropdown(user?.user_id, index)}
                        >
                          <td>{user.name}</td>
                          <td>{user.email}</td>
                          <td>{user.region}</td>
                          <td>{user.country}</td>
                          <td>{user.liveSpendTime} min</td>
                          <td>{user.askedQuestion}</td>
                          <td>{user.pollParticipate}</td>
                        </tr>
                        <tr
                          className={
                            "fold " + (currentIndex === index ? "show" : "")
                          }
                        >
                          <td colSpan="8">
                            <div className="fold-content d-flex justify-content-between">
                              <Col className="fold-content-left">
                                <Table>
                                  <thead>
                                    <tr>
                                      <th>
                                        Questions asked through the live stream
                                      </th>
                                      <th>Time</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {attendeesDataDropdown?.livestreamQuestion
                                      ?.length ? (
                                      attendeesDataDropdown?.livestreamQuestion?.map(
                                        (question, index) => (
                                          <tr key={index}>
                                            <td>{question?.question}</td>
                                            <td>{question?.time}</td>
                                          </tr>
                                        )
                                      )
                                    ) : (
                                      <tr>
                                        <td colSpan="2">
                                          <div className="no_found">
                                            <p>No Question Asked</p>
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </Table>
                              </Col>
                              <Col className="fold-content-right">
                                <Table>
                                  <thead>
                                    <tr>
                                      <th>Answered poll question</th>
                                      <th>Chosen answer</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {attendeesDataDropdown?.pollQuestions
                                      ?.length ? (
                                      attendeesDataDropdown?.pollQuestions?.map(
                                        (question, index) => (
                                          <tr key={index}>
                                            <td>{question?.question}</td>
                                            <td>{question?.answer}</td>
                                          </tr>
                                        )
                                      )
                                    ) : (
                                      <tr>
                                        <td colSpan="2">
                                          <div className="no_found">
                                            <p>No Poll Submitted</p>
                                          </div>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </Table>
                              </Col>
                            </div>
                          </td>
                        </tr>
                        <tr className="blank">
                          <td colSpan="7">&nbsp;</td>
                        </tr>
                      </>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7">
                        <div className="no_found">
                          <p>No Data Found</p>
                        </div>
                      </td>
                    </tr>
                  )}

                  <tr className="blank">
                    <td colSpan="7">&nbsp;</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </Row>}
        </div>
      </Col>
    </>
  );
};

export default AnalyticsAttendees;
