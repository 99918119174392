export const countryRegionArray = {
    Afghanistan: 'Other',
    Albania: 'CEE/CIS',
    Algeria: 'African Group 1',
    'American Samoa': 'Other',
    Andorra: 'Other',
    Angola: 'Other',
    Anguilla: 'Other',
    Antarctica: 'Other',
    'Antigua and Barbuda': 'Other',
    Argentina: 'LATAM',
    Armenia: 'Other',
    Aruba: 'Other',
    Australia: 'Other',
    Austria: 'EU',
    Azerbaijan: 'CEE/CIS',
    'Bosnia and Herzegovina': 'CEE/CIS',
    Bahamas: 'LATAM',
    Bahrain: 'MENA',
    Bangladesh: 'TINBS',
    Barbados: 'LATAM',
    Belarus: 'CEE/CIS',
    Belgium: 'EU',
    Belize: 'LATAM',
    Benin: 'African Group 1',
    Bermuda: 'Other',
    Bhutan: 'Other',
    Bolivia: 'LATAM',
    Botswana: 'African Group 1',
    'Bouvet Island': 'Other',
    Brazil: 'Brazil',
    'British Indian Ocean Territory': 'Other',
    'Brunei Darussalam': 'Other',
    Bulgaria: 'CEE/CIS',
    'Burkina Faso': 'African Group 1',
    Burundi: 'African Group 1',
    Cambodia: 'Other',
    Cameroon: 'African Group 1',
    Canada: 'Canada',
    'Cape Verde': 'Other',
    'Cayman Islands': 'Other',
    'Central African Republic': 'African Group 1',
    Chad: 'African Group 1',
    Chile: 'LATAM',
    China: 'Other',
    'Christmas Island': 'Other',
    'Cocos (Keeling) Islands': 'Other',
    Colombia: 'LATAM',
    Comoros: 'African Group 1',
    Congo: 'African Group 1',
    'Congo, The Democratic Republic of The': 'African Group 1',
    'Cook Islands': 'Other',
    'Costa Rica': 'LATAM',
    "Cote D'ivoire": 'African Group 1',
    Croatia: 'CEE/CIS',
    Cuba: 'LATAM',
    Cyprus: 'Other',
    'Czech Republic': 'CEE/CIS',
    Denmark: 'EU',
    Djibouti: 'African Group 1',
    Dominica: 'Other',
    'Dominican Republic': 'LATAM',
    Ecuador: 'LATAM',
    Egypt: 'MENA',
    'El Salvador': 'LATAM',
    'Equatorial Guinea': 'Other',
    Eritrea: 'African Group 1',
    Estonia: 'CEE/CIS',
    Eswatini: 'African Group 1',
    Ethiopia: 'African Group 1',
    'Falkland Islands (Malvinas)': 'Other',
    'Faroe Islands': 'Other',
    Fiji: 'Other',
    Finland: 'EU',
    France: 'EU',
    'French Guiana': 'Other',
    'French Polynesia': 'Other',
    'French Southern Territories': 'Other',
    Gabon: 'African Group 1',
    Gambia: 'African Group 1',
    Georgia: 'CEE/CIS',
    Germany: 'EU',
    Ghana: 'African Group 1',
    Gibraltar: 'Other',
    Greece: 'EU',
    Greenland: 'Other',
    Grenada: 'Other',
    Guadeloupe: 'Other',
    Guam: 'Other',
    Guatemala: 'LATAM',
    Guinea: 'African Group 1',
    'Guinea-bissau': 'Other',
    Guyana: 'Other',
    Haiti: 'Other',
    'Heard Island and Mcdonald Islands': 'Other',
    'Holy See (Vatican City State)': 'Other',
    Honduras: 'LATAM',
    'Hong Kong': 'Other',
    Hungary: 'CEE/CIS',
    IBU: 'Other',
    Iceland: 'Other',
    India: 'TINBS',
    Indonesia: 'SEA',
    'Iran, Islamic Republic of': 'Other',
    Iraq: 'MENA',
    Ireland: 'EU',
    Israel: 'MENA',
    Italy: 'EU',
    Jamaica: 'LATAM',
    Japan: 'Other',
    Jordan: 'MENA',
    Kazakhstan: 'CEE/CIS',
    Kenya: 'African Group 1',
    Kiribati: 'Other',
    Korea: 'Other',
    "Korea, Democratic People's Republic of": 'Other',
    'Korea, Republic of': 'Other',
    Kosovo: 'Other',
    Kuwait: 'MENA',
    Kyrgyzstan: 'Other',
    "Lao People's Democratic Republic": 'Other',
    Latvia: 'CEE/CIS',
    Lebanon: 'MENA',
    Lesotho: 'African Group 1',
    Liberia: 'African Group 1',
    Libya: 'African Group 1',
    'Libyan Arab Jamahiriya': 'Other',
    Liechtenstein: 'Other',
    Lithuania: 'CEE/CIS',
    Luxembourg: 'Other',
    Macao: 'Other',
    Madagascar: 'African Group 1',
    Malawi: 'African Group 1',
    Malaysia: 'SEA',
    Maldives: 'Other',
    Mali: 'African Group 1',
    Malta: 'Other',
    'Marshall Islands': 'Other',
    Martinique: 'Other',
    Mauritania: 'African Group 1',
    Mauritius: 'Other',
    Mayotte: 'Other',
    Mexico: 'Mexico',
    'Micronesia, Federated States of': 'Other',
    'Moldova, Republic of': 'Other',
    Monaco: 'Other',
    Mongolia: 'Other',
    Montenegro: 'CEE/CIS',
    Montserrat: 'Other',
    Morocco: 'African Group 1',
    Mozambique: 'Other',
    Myanmar: 'Other',
    Namibia: 'African Group 1',
    Nauru: 'Other',
    Nepal: 'TINBS',
    Netherlands: 'EU',
    'Netherlands Antilles': 'Other',
    'New Caledonia': 'Other',
    'New Zealand': 'Other',
    Nicaragua: 'Other',
    Niger: 'African Group 1',
    Nigeria: 'African Group 1',
    Niue: 'Other',
    Nordic: 'Other',
    'Norfolk Island': 'Other',
    'North Macedonia': 'CEE/CIS',
    'Northern Mariana Islands': 'Other',
    Norway: 'EU',
    Oman: 'MENA',
    Pakistan: 'MENA',
    Palau: 'Other',
    'Palestinian Territory, Occupied': 'Other',
    Panama: 'LATAM',
    'Papua New Guinea': 'Other',
    Paraguay: 'LATAM',
    Peru: 'LATAM',
    Philippines: 'SEA',
    Pitcairn: 'Other',
    Poland: 'CEE/CIS',
    Portugal: 'EU',
    'Puerto Rico': 'Other',
    Qatar: 'MENA',
    Reunion: 'Other',
    Romania: 'CEE/CIS',
    Russia: 'Russian Federation',
    'Russian Federation': 'Russian Federation',
    Rwanda: 'African Group 1',
    'Saint Helena': 'Other',
    'Saint Kitts and Nevis': 'Other',
    'Saint Lucia': 'Other',
    'Saint Pierre and Miquelon': 'Other',
    'Saint Vincent and The Grenadines': 'Other',
    Samoa: 'Other',
    'San Marino': 'Other',
    'Sao Tome and Principe': 'Other',
    'Saudi Arabia': 'MENA',
    Senegal: 'African Group 1',
    Serbia: 'CEE/CIS',
    Seychelles: 'African Group 1',
    'Sierra Leone': 'African Group 1',
    Singapore: 'SEA',
    Slovakia: 'CEE/CIS',
    Slovenia: 'CEE/CIS',
    'Solomon Islands': 'Other',
    Somalia: 'African Group 1',
    'South Africa': 'ZAF',
    'South Georgia and The South Sandwich Islands': 'Other',
    Spain: 'EU',
    'Sri Lanka': 'TINBS',
    Sudan: 'MENA',
    Suriname: 'Other',
    'Svalbard and Jan Mayen': 'Other',
    Swaziland: 'Other',
    Sweden: 'EU',
    Switzerland: 'EU',
    'Syrian Arab Republic': 'MENA',
    'São Tomé and Príncipe': 'African Group 1',
    'Taiwan, Province of China': 'Other',
    Tajikistan: 'Other',
    'Tanzania, United Republic of': 'African Group 1',
    Thailand: 'SEA',
    'Timor-leste': 'Other',
    Togo: 'African Group 1',
    Tokelau: 'Other',
    Tonga: 'Other',
    'Trinidad and Tobago': 'LATAM',
    Tunisia: 'African Group 1',
    Turkey: 'TINBS',
    Turkmenistan: 'Other',
    'Turks and Caicos Islands': 'Other',
    Tuvalu: 'Other',
    Uganda: 'African Group 1',
    Ukraine: 'CEE/CIS',
    'United Arab Emirates': 'MENA',
    'United Kingdom': 'EU',
    'United States': 'US',
    'United States Minor Outlying Islands': 'Other',
    Uruguay: 'LATAM',
    Uzbekistan: 'CEE/CIS',
    Vanuatu: 'Other',
    Venezuela: 'LATAM',
    'Viet Nam': 'SEA',
    'Virgin Islands, British': 'Other',
    'Virgin Islands, U.S.': 'Other',
    'Wallis and Futuna': 'Other',
    'Western Sahara': 'Other',
    Yemen: 'MENA',
    Palestine: 'MENA',
    Zambia: 'African Group 1',
    Zimbabwe: 'African Group 1',
    Curacao: 'LATAM',
  };